/* ... existing styles ... */

.header {
  display: flex;
  align-items: center;
  padding: 20px 10%;
  background-color: #ffffff;
}

.nav {
  margin-left: auto;
}

.nav a {
  margin-left: 20px;
  text-decoration: none;
  color: #666;
  font-size: 16px;
}

.nav a:hover {
  color: #000;
}


/* Hero Section */
.hero {
  position: relative;
  background: rgba(0, 0, 0, 0); /*url('./assets/visual.jpg') no-repeat center center/cover;*/
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
}

.hero-content {
  position: relative;
  color: #fff;
  font-palette: light;
  text-align: center;
  padding: 0 20px;
}

.hero h1 {
  font-size: 48px;
  margin-bottom: 20px;
  color: black;
}

.hero p {
  font-size: 24px;
  margin-bottom: 30px;
  color: black;
}

.cta-button {
  padding: 15px 30px;
  font-size: 18px;
  background-color: #ff6600;
  color: #fff;
  border: none;
  cursor: pointer;
}

.cta-button:hover {
  background-color: #e65c00;
}

/* Features Section */
.features {
  padding: 80px 10%;
  text-align: center;
}

.features h2 {
  font-size: 36px;
  margin-bottom: 50px;
}

.features-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.feature {
  width: 30%;
  min-width: 250px;
  margin-bottom: 40px;
}

.feature h3 {
  font-size: 24px;
  margin-top: 20px;
}

.feature p {
  font-size: 16px;
  color: #666;
}

.feature svg {
  color: #ff6600;
}

/* App Preview Section */
.app-preview {
  padding: 80px 10%;
  background-color: #f9f9f9;
  text-align: center;
}

.app-preview h2 {
  font-size: 36px;
  margin-bottom: 50px;
}

.preview-images {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.preview-images img {
  width: 30%;
  min-width: 200px;
  margin: 0 10px 20px;
}

/* Testimonials Section */
.testimonials {
  padding: 80px 10%;
  text-align: center;
}

.testimonials h2 {
  font-size: 36px;
  margin-bottom: 50px;
}

.testimonials-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.testimonial {
  width: 60%;
  margin-bottom: 40px;
}

.testimonial p {
  font-size: 20px;
  font-style: italic;
  color: #333;
}

.testimonial h4 {
  margin-top: 10px;
  font-size: 18px;
  color: #666;
}

/* Footer */
.footer {
  text-align: center;
  padding: 20px 0;
  background-color: #333;
  color: #fff;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero h1 {
    font-size: 36px;
  }

  .hero p {
    font-size: 18px;
  }

  .features-container,
  .preview-images {
    flex-direction: column;
    align-items: center;
  }

  .feature,
  .preview-images img {
    width: 80%;
  }

  .feature {
    margin-bottom: 60px;
  }
}
